<template>
  <div v-show="active" v-if="!isHidden" class="tab-content">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Tab"
    },
    isActive: {
      type: Boolean,
      default: true
    },
    isHidden: {
      type: Boolean,
      default: false
    },
    change: {
      type: Function,
      default: null
    },
    id: {
      type: String,
      default: "",
      required: true
    }
  },
  data() {
    return {
      active: this.isActive
    };
  },
  watch: {
    isActive(newVal) {
      this.active = newVal;
    }
  }
};
</script>
