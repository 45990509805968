<template>
  <div style="margin-bottom: 20px">
    <div class="d-flex align-items-center">
      <div>
        <button
          class="btn btn-primary-light"
          type="button"
          @click="$emit('handleSearch')"
        >
          Sprawdź
          <template v-if="isLoading">
            <i class="fa fa-spin fa-spinner ms-2"></i>
          </template>
        </button>
      </div>
      <div v-if="isLoaded !== null" style="margin-left: 50px">
        <div class="d-flex align-items-center">
          <div>
            <img :src="getLoadedIcon" alt="" />
          </div>
          <div style="margin-left: 15px">
            <p
              class="m-0"
              :class="isLoaded === true ? 'text-success' : 'text-info'"
            >
              <template v-if="isLoaded === true">{{ successText }}</template>
              <template v-else>{{ errorText }}</template>
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="additionalSearch && isLoaded === false"
        style="margin-left: 40px"
      >
        <button type="button" class="btn btn-outline-primary">
          Pobierz CEDIG
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchGroup",
  props: {
    successText: {
      type: String,
      default: ""
    },
    errorText: {
      type: String,
      default: ""
    },
    successIcon: {
      type: String,
      default: ""
    },
    errorIcon: {
      type: String,
      default: ""
    },
    isLoaded: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    additionalSearch: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getLoadedIcon() {
      return this.isLoaded
        ? `/media/components/form/${this.successIcon}.svg`
        : `/media/components/form/${this.errorIcon}.svg`;
    }
  }
};
</script>
