export default value => {
  if (value === "11111111111") {
    return true;
  }
  let reg = /^[0-9]{11}$/;
  if (reg.test(value) === false) return false;
  else {
    let digits = ("" + value).split("");
    if (
      parseInt(value.substring(4, 6)) > 31 ||
      parseInt(value.substring(2, 4)) > 12
    )
      return false;

    let checksum =
      (parseInt(digits[0]) +
        3 * parseInt(digits[1]) +
        7 * parseInt(digits[2]) +
        9 * parseInt(digits[3]) +
        parseInt(digits[4]) +
        3 * parseInt(digits[5]) +
        7 * parseInt(digits[6]) +
        9 * parseInt(digits[7]) +
        parseInt(digits[8]) +
        3 * parseInt(digits[9])) %
      10;
    if (checksum === 0) checksum = 10;
    checksum = 10 - checksum;

    return parseInt(digits[10]) === checksum;
  }
};
