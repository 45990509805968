<template>
  <div class="tabs">
    <ul class="tabs-header">
      <template v-for="(tab, index) in tabs">
        <li
          v-if="!tab.isHidden"
          :key="index"
          :class="{ 'tab-active': tab.active }"
          @click="selectTab(index)"
        >
          {{ tab.title }}
        </li>
      </template>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: [] // all of the tabs
    };
  },

  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(0);
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;
      if (this.tabs.length) {
        this.tabs[i].change(this.tabs[i].id);
        this.tabs.forEach((tab, index) => {
          tab.active = index === i;
        });
      }
    }
  }
};
</script>
